
const books = [
  {
    title: 'Shit Tits Fuck Cunt',
    id: 'shit_tits_fuck_cunt',
    price: 369,
  },
  {
    title: 'Edison',
    id: 'edison',
    price: 150,
  },
  {
    title: 'Tyrolské Elegie',
    id: 'tyrolske_elegie',
    price: 366,
  },
]

const posters = [
  {
    title: 'The Trip',
    id: 'the_trip'
  },
  {
    title: 'Cluster f*ck',
    id: 'cluster_fuck'
  },
  {
    title: 'Neděle bez moučníku je jako obloha bez sluníčka',
    id: 'nedele_bez_slunicka'
  },
  {
    title: 'Sisyphus',
    id: 'sisiphus'
  },
  {
    title: 'The F Word',
    id: 'the_f_word'
  },
  {
    title: 'Genital forest',
    id: 'genital_forest'
  },
  {
    title: 'Edison',
    id: 'edison'
  },
  {
    title: 'Aby ti matku pojebal',
    id: 'aby_ti_matku_pojebal'
  },
  {
    title: 'Kdo krká okrádá prdel',
    id: 'kdo_krka'
  },
  {
    title: 'KHB1',
    id: 'KHB1'
  },
  {
    title: 'KHB2',
    id: 'KHB2'
  },
]

const stuff = [
  {
    title: 'balící papír',
    id: 'balici_papir',
  },
  /*{
    title: 'trika',
  },
  {
    title: 'nášivky',
  },
  */
]

const postcards = [
  {
    title: 'Aby ti matku pojebal',
    id: 'aby_ti_matku_pojebal'
  },
  {
    title: 'The Date with Pamela Handerson',
    id: 'the_date_with_pamela_handerson'
  },
  {
    title: 'Vyklikat myšku',
    id: 'vyklikat_mysku'
  },
  {
    title: 'Helicopter dick',
    id: 'helicopter_dick'
  },
  {
    title: 'Cluster f*ck',
    id: 'cluster_fuck'
  },
  {
    title: 'Buchto miluju tě',
    id: 'buchto_miluju_te'
  },
  {
    title: 'Teleport Trouble',
    id: 'teleport_trouble'
  },
  {
    title: 'Kdo krká okrádá prdel',
    id: 'kdo_krka'
  },
  {
    title: 'KHB1',
    id: 'KHB1'
  },
  {
    title: 'KHB2',
    id: 'KHB2'
  },
  {
    title: 'Shark week',
    id: 'shark_week'
  },
  {
    id: 'adulthood_is_a_fucking_lie',
    title: 'Adulthood is a Fucking Lie',
  },
  {
    id: 'dosel_toner',
    title: 'Došel toner',
  },
  {
    id: 'jarni_pyj',
    title: 'Jarní pyj'
  },
  {
    id: 'kill_shit',
    title: 'Kill Shit'
  },
  {
    id: 'tak_ale_do_pyce',
    title: 'Tak ale do pyče'
  }
]




const content = [
  {
    title: 'KNIHY',
    items: books,
    dir: 'books',
    slug: '/knihy',
  },
  {
    title: 'PLAKÁTY',
    items: posters,
    dir: 'posters',
    slug: '/plakaty',
  },
  /*{
    title: 'VĚCI',
    items: stuff,
    dir: 'stuff',
    slug: '/veci',
  },
  */
  {
    title: 'POHLEDY',
    items: postcards,
    dir: 'postcards',
    slug: '/pohledy',
  },
]

module.exports = content