import * as THREE from 'three'
import React, { useRef, useState, useEffect } from 'react'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { navigate, useStaticQuery, graphql } from 'gatsby'


const BOOK_SCALE = 0.8

const Book = ({
  material,
  geometry,
  position,
  id,
  title,
  price,
  state,
  setState,
  bookPreviewImages
}) => {
  const mesh = useRef()

  const [speed, setSpeed] = useState((Math.random() + 1) * 0.5)
  const [directionX] = useState((Math.random() > 0.5 ? 1 : -1))
  const [directionZ] = useState((Math.random() > 0.5 ? 1 : -1))
  const [directionY] = useState((Math.random() > 0.5 ? 1 : -1))

  useEffect(() => {
    mesh.current.rotation.x = Math.random()
    mesh.current.rotation.y = Math.random()
    mesh.current.rotation.z = Math.random()
  }, [])

  useFrame(() => {
    mesh.current.rotation.y += directionY * 0.03 * speed
    mesh.current.rotation.z += directionZ * 0.01 * speed
    mesh.current.rotation.x += directionX * 0.02 * speed
  })

  return <mesh
    ref={mesh}
    onPointerOver={(e) => {
      document.body.style.cursor = 'pointer';
      setState({
        ...state,
        hoveredBook: {
          id,
          x: e.pageX,
          y: e.pageY,
          type: 'books',
          title,
          price,
        }
      })
    }}
    onPointerOut={(e) => {
      document.body.style.cursor = 'default';
      setState({
        ...state,
        // hoveredBook: null,
      })
    }}
    onClick={() => {
      navigate(`/knihy/${id}`)
    }}
    material={material}
    geometry={geometry}
    position={position}
    scale={[BOOK_SCALE, BOOK_SCALE, BOOK_SCALE]}
  />
}

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useLoader(GLTFLoader, '/mrdka2.glb')

  const allImages = props.data.allFile.nodes.filter(n => n.childImageSharp)

  const injectRelevantImages = (book) => {
    const bookPreviewImages = allImages
      .filter(n => (n.relativePath.indexOf(book.id) >= 0))
      .sort((a, b) => (a.relativePath > b.relativePath) ? 1 : -1)

    return {
      ...book,
      bookPreviewImages
    }
  }

  const tyrolskeElegie = injectRelevantImages({
    material: materials.Material,
    geometry: nodes.tyrolske_elegie.geometry,
    id: 'tyrolske_elegie',
    title: 'Tyrolské Elegie',
    price: 150,
  })

  const shitsTits = injectRelevantImages({
    material: materials['Material.002'],
    geometry: nodes.shits_tits.geometry,
    id: 'shit_tits_fuck_cunt',
    title: 'Shit Tits Fuck Cunt',
    price: 369,
  })

  const edison = injectRelevantImages({
    material: materials['Material.003'],
    geometry: nodes.edison.geometry,
    id: 'edison',
    title: 'Edison',
    price: 366,
  })

  const items = [
    shitsTits,
    tyrolskeElegie,
    shitsTits,
    edison,
    shitsTits,
    tyrolskeElegie,
    edison,
    shitsTits,
    tyrolskeElegie,
    edison,
  ]

  const X_SCALE_RATIO = 1.3;
  const Y_SCALE_RATIO = 1;

  const xScale = num => (num * X_SCALE_RATIO) - ((items.length / 2) * X_SCALE_RATIO)
  // const yScale = num => Math.sin(num * 4) * 1.4 * Y_SCALE_RATIO // - ((items.length / 2) * Y_SCALE_RATIO)

  const yByIndex = [
    -1,
    2,
    -3,
    -0,
    2.5,
    -2.7,
    0,
    2,
    -2,
    0
  ]

  const yScale = num => yByIndex[num];

  return (
    <group ref={group} {...props} dispose={null} rotation={[0, -Math.PI / 2, 0]}
      position={[-1, 0, -1000]}>
      {items.map((item, i) => {
        return <Book
          key={i}
          state={props.state}
          setState={props.setState}
          {...item}
          position={[0, yScale(i), xScale(i)]}
        />
      })}
    </group>
  )
}
