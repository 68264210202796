import * as THREE from 'three'
import React, { Suspense, useState, useRef } from 'react'

import Snake from "../components/snake-head"
import Books from "../components/books"

import { Canvas, useFrame } from 'react-three-fiber'

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? [1.5, 1.5, 1.5] : [100, 100, 100]}
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}>
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}


const HeadCanvas = () => (
  <Canvas
    onCreated={({ gl }) => {
      // Comment the next line: colors are good, hightlights blown out
      // gl.toneMapping = THREE.ACESFilmicToneMapping
      // gl.toneMapping = THREE.ACESFilmicToneMapping
      // gl.toneMapping = THREE.ACESFilmicToneMapping
      gl.toneMapping = THREE.LinearToneMapping
      // gl.toneMapping = THREE.CineonToneMapping
      gl.outputEncoding = THREE.sRGBEncoding
    }}
    orthographic={true}
    // camera={{ fov: 160, near: 0.1, far: 1000, z: 7 }}
    camera={{ near: 0.0001, far: 10000, z: -1000 }}
    style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none' }}
  >
    <ambientLight intensity={0.1} />
    <pointLight position={[0, 0, 100,]} intensity={0.8} />
    {/* <pointLight position={[100, 0, 100,]} intensity={0.8} /> */}
    {/* <pointLight position={[0, 100, 100,]} intensity={0.8} /> */}
    {/* <pointLight position={[100, 100, -100,]} intensity={0.8} /> */}
    {/* <Box position={[0, 0, -100]} /> */}
    {/* <Box position={[1.2, 0, 0]} /> */}
    <Suspense fallback={null}>
      <Snake />
    </Suspense>
  </Canvas>
)

export default HeadCanvas
