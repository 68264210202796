import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GLOBAL_PADDING } from '../contants'
import Header from "./header"
import HeadCanvas from './head-canvas'
import FixedBottom from './fixed-bottom'
import "./layout.css"
import StateContext from './state-context'
import Img from "gatsby-image"
import { useMemo } from "react"
import styled from 'styled-components'

const MobileInfoWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 2.75rem;
  text-transform: uppercase;
  color: white;
  @media only screen and (min-width: 680px) {
    display: none;
  }
`


function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const HoveredBook = ({ x, y, imageNodes, title, price, showImage }) => {

  const [index, setIndex] = useState(0);

  const limit = Math.min(10, imageNodes.length)

  useInterval(
    () => setIndex(((index + 1) % limit)),
    150
  )

  return (
    <div style={{
      position: 'absolute',
      top: y,
      left: x,
      zIndex: 999999,
      flex: 1,
      pointerEvents: 'none',
      color: 'white',
      fontSize: '300%',
    }}>
      {Boolean(showImage) ?
        <Img style={{ width: '250px', background: 'black' }} fluid={imageNodes[index].childImageSharp.fluid} />
        : <p>{title}, {price} Kč {}</p>
      }
    </div>
  )
}


const Layout = ({ children, fixedBottom, justHead = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      books: allFile(filter: {relativePath: {glob: "content/books/**/cela_kniha/**"}}) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      postcards: allFile(filter: {relativePath: {glob: "content/postcards/*.png"}}) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      posters: allFile(filter: {relativePath: {glob: "content/posters/*.png"}}) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const [state, setState] = useState({})

  const { hoveredBook } = state

  const hoveredBookPopup = useMemo(
    () => {
      if (!hoveredBook) return null;
      const { id, type } = hoveredBook;

      const allPreviewImageNodes = (
        ((data[type] || {}).nodes) || []
      )
        .filter(n => n.childImageSharp)
        .filter(n => (n.relativePath.indexOf(id) >= 0))
        .sort((a, b) => (a.relativePath > b.relativePath) ? 1 : -1);

      if (!allPreviewImageNodes.length) return null;

      return <HoveredBook
        key={id}
        {...hoveredBook}
        imageNodes={allPreviewImageNodes}
      />
    },
    [hoveredBook, data],
  )

  return (
    <StateContext.Provider value={{ state, setState }}>
      <>
        <Header siteTitle="Deset Deka Dekadence" setState={setState} state={state} />
        <div
          style={{
            margin: `0 auto`,
            padding: `${GLOBAL_PADDING}px`,
          }}
        >
          <main>{children}</main>
          <HeadCanvas />
          {hoveredBookPopup}
        </div>
        {fixedBottom && <FixedBottom>{fixedBottom}</FixedBottom>}
        <MobileInfoWrapper>
          Až dosereš, koukni se na web na počítači
        </MobileInfoWrapper>
      </>
    </StateContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
