import * as THREE from 'three'
// import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js'
import React, { useRef, useEffect } from 'react'
import { useLoader, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

const SCALE = 0.5;

const material = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  metalness: 0.99,
  roughness: 0.3
});


export default function Model(props) {
  const group = useRef()
  const mesh = useRef()
  const { nodes } = useLoader(GLTFLoader, '/snake2.glb')

  /*
  useEffect(() => {
    var vertexNormalsHelper = new VertexNormalsHelper(mesh.current, 100);
    mesh.current.add(vertexNormalsHelper);
  }, [])
  */
  // /*
  useFrame(() => {
    mesh.current.time = mesh.current.time || 0
    mesh.current.time += 5
    mesh.current.speed = mesh.current.speed || 7;

    mesh.current.xDirection = mesh.current.xDirection || 1;
    mesh.current.yDirection = mesh.current.yDirection || 1;
    mesh.current.rotation.y += 0.03
    mesh.current.rotation.z -= 0.01
    mesh.current.rotation.x += 0.02

    mesh.current.position.x += mesh.current.xDirection * mesh.current.speed
    mesh.current.position.y += mesh.current.yDirection * mesh.current.speed

    const { snek: { geometry: { boundingBox: { min, max } } } } = nodes;

    const wW = window.innerWidth;
    const wH = window.innerHeight;

    const maxX = wW / 2
    const minX = maxX * -1;

    const maxY = wH / 2
    const minY = maxY * -1;

    const meshPosX = mesh.current.position.x
    const meshPosY = mesh.current.position.y


    if (meshPosX + min.x * SCALE < minX || meshPosX + max.x * SCALE > maxX) {
      mesh.current.xDirection *= -1;
    }

    if (meshPosY + min.y * SCALE < minY || meshPosY + max.y * SCALE > maxY) {
      mesh.current.yDirection *= -1;
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>

      <mesh
        ref={mesh}
        material={material}
        geometry={nodes.snek.geometry}
        scale={[SCALE, SCALE, SCALE]}
        position={[0, 0, -400]}
      >
        {/* <boxBufferGeometry attach="geometry" args={[1, 1, 1]} /> */}
      </mesh>
    </group>
  )
}
