import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import { GLOBAL_PADDING } from '../contants'
import Menu from "./menu"
import styled from 'styled-components';
import logoSvg from '../images/logo.svg';
import podLogemSvg from '../images/pod-logem.svg';
import { Helmet } from "react-helmet"

const Container = styled.header`
  position: fixed;
  padding: ${GLOBAL_PADDING}px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: black;
  mix-blend-mode: ${({ open }) => open ? 'unset' : 'difference'};
  bottom: ${({ open }) => open ? 0 : 'unset'};
`

const MenuAnchor = styled.div`
  position: relative;
`

const MenuContainer = styled.div`
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  pointer-events: ${({ open }) => open ? 'unset' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 9999;
`


const Header = ({ siteTitle, setState, state }) => {
  const [open, setOpen] = useState(false);
  const [fontSize, setFontSize] = useState(20);
  const logo = useRef();
  const menu = useRef();

  const handleOpenMenu = (isOpen) => {
    setState({ hoveredBook: null })
    setOpen(isOpen)
  }

  useEffect(
    () => {
      if (typeof window === 'undefined') {
        return
      }

      if (!logo.current || !menu.current) {
        return
      }

      const logoRect = logo.current.getBoundingClientRect();
      const menuRect = menu.current.getBoundingClientRect();

      const remainingHeight = window.innerHeight - logoRect.height - menuRect.height;

      if (remainingHeight > window.innerHeight * 0.05) {
        const inc = remainingHeight < window.innerHeight * 0.3 ? 1 : 5
        setFontSize(fontSize + inc)
      }
    },
    [logo.current, menu.current, fontSize, open],
  );

  return (
    <Container open={open}>
      <Helmet>
        <title>Deset Deka Dekadence</title>
      </Helmet>

      <img
        src={logoSvg}
        onClick={() => handleOpenMenu(!open)}
        style={{
          width: '100%',
          maxWidth: '100%',
          display: 'block',
          margin: 0,
          paddingBottom: '10px',
          cursor: 'pointer',
          mixBlendMode: 'difference',
        }}
        ref={logo}
      />
      {!open &&
        <img
          src={podLogemSvg}
          onClick={() => handleOpenMenu(!open)}
          style={{
            width: '100%',
            maxWidth: '100%',
            display: 'block',
            margin: 0,
            paddingBottom: '10px',
            cursor: 'pointer',
            mixBlendMode: 'difference',
            transform: 'scale(1, 0.5)',
            transformOrigin: '0 0'
          }}
        />
      }
      <MenuAnchor>
        <MenuContainer
          ref={menu}
          open={open}
        >
          <Menu fontSize={fontSize} onItemClick={() => handleOpenMenu(false)} />
        </MenuContainer>
      </MenuAnchor>
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
