import React, { useState, useRef, useEffect } from "react"
import { GLOBAL_PADDING } from '../contants'
import styled from 'styled-components';

const FixedBottom = styled.div`
  position: fixed;
  padding: ${GLOBAL_PADDING}px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 666;
  background: black;
  mix-blend-mode: difference;
`

export default FixedBottom;