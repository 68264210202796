import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import sections from '../content'
import StateContext from "./state-context"
import { BUY_LINK } from "../contants"

const Container = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0;
  font-size: ${({ fontSize }) => fontSize + 'px'};
  background: black;
`

const Inner = styled.div`
  line-height: 1.2;
`

const ItemLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    color: black;
    background: white;
  }
`

const MainLinkText = styled(Link)`
  text-decoration: none;
  color: white;
  &:hover {
    background-color: white;
    color: black;
  }
`

const MainLinkUnderline = styled.span`
  background-image: linear-gradient(white, white);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: 0px 95%;
`

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    color: black;
    background: white;
  }
`

const Arrow = styled.span`
  cursor: default;
`

const MainLink = ({ children, to, withArrow = true, ...other }) => {
  return (
    <span>
      <MainLinkUnderline>
        <MainLinkText to={to} {...other}>
          {children}
        </MainLinkText>
      </MainLinkUnderline>
      {withArrow && <Arrow>{'->'}</Arrow>}
    </span>
  )
}

const Section = ({ dir, title, items, slug, onItemClick }) => {
  return (
    <StateContext.Consumer>
      {({ state, setState }) => (
        <React.Fragment>
          <MainLink
            onClick={onItemClick}
            to={slug}
            onMouseEnter={(e) => {
              setState({
                ...state,
                hoveredBook: null
              })
            }}
          >
            {title}
          </MainLink>
          {items.map((item, index) => {
            return <span
              onClick={(e) => {
                setState({
                  ...state,
                  hoveredBook: null
                })
              }}
              onMouseEnter={(e) => {
                setState({
                  ...state,
                  hoveredBook: {
                    type: dir,
                    id: item.id,
                    x: e.pageX,
                    y: e.pageY,
                    showImage: true,
                    ...item,
                  }
                })
              }}
              key={index}
            >
              <ItemLink to={`${slug}/${item.id}`}>{item.title}</ItemLink>
              {index === items.length - 1 ? '. ' : ', '}
            </span>
          })}
        </React.Fragment>
      )
      }
    </StateContext.Consumer >
  )
}

const Menu = ({ fontSize, onItemClick, ...rest }) => {
  return (
    <Container {...rest} fontSize={fontSize}>
      <Inner>
        {sections.map((s, i) => <Section key={i} onItemClick={onItemClick} {...s} />)}
        <MainLink onClick={onItemClick} to="/o-nas">O NÁS</MainLink>
        <ItemLink href="/o-nas">Kontakt</ItemLink>,{' '}
        <ExternalLink href="https://www.facebook.com/desetdekadekadence">Facebook</ExternalLink>,{' '}
        <ExternalLink href="https://www.instagram.com/desetdekadekadence/">Instagram</ExternalLink>.{' '}
        <ExternalLink target="_blank" href={BUY_LINK}>KOUPIT</ExternalLink>
      </Inner>
    </Container>
  )
}


export default Menu
